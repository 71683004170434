<template>
  <div class="my-plan custom-z-index">
    <section class="container">
      <div class="my-plan-nav fs-body-16 d-flex flex-nowrap">
        <div
            v-for="(item, index) in navInfo"
            :key="index"
            :class="[ 'nav-item', { active: index === currentNav } ]"
            @click="toggleNav(index, item)"
        >
          <span>{{ item.name }}</span>
        </div>
      </div>
<!--      <MyPlanGroup :plan-list-data="groupList"></MyPlanGroup>-->

      <keep-alive>
        <component v-bind:is="currentNavComponent" :plan-list-data="currentComponentData" :plan-type="navInfo[currentNav].name" @delete-btn-clicked="deleteBtnClicked(arguments)"></component>
      </keep-alive>

    </section>
  </div>
</template>

<script>
import moment from "moment";
import {mapActions} from "vuex";
import MyPlanGroup from "@/views/plan/MyPlanGroup.vue";
import MyPlanFavorite from "@/views/plan/MyPlanFavorite";
import MyPlanJoinedGroup from "@/views/plan/MyPlanJoinedGroup.vue";
import MyPlanTrip from "@/views/plan/MyPlanTrip";
import MyPlanJoined from "@/views/plan/MyPlanJoined";

export default {
  name: 'MyPlan',
  data() {
    return {
      readInfoBusyName: 'READINFOBUSY',

      currentNav: 0,
      navInfo: [
        { name: '登山計畫', length: 0 },
        { name: '收藏的路線', length: 0 },
        { name: '參加的活動', length: 0 },
        // { name: '舊的行程頁', length: 0 },
        // { name: '舊的已加入', length: 0 },
      ],
      groupList: [],
      joinedGroupList: [],
      journeys: [],
      favorites: [],
      joinedList: [],
    };
  },
  props: {
  },
  components: {
    MyPlanGroup,
    MyPlanFavorite,
    MyPlanJoinedGroup,
    MyPlanTrip,
    MyPlanJoined,
  },
  computed: {
    user() {
      return this.$store.getters.userData;
    },
    currentNavComponent: function() {
      let target
      switch (this.navInfo[this.currentNav].name) {
        case '登山計畫':
          target = 'my-plan-group'
          break;
        case '收藏的路線':
          target = 'my-plan-favorite'
          break;
        case '參加的活動':
          target = 'my-plan-joined-group'
          break;
        case '舊的行程頁':
          target = 'my-plan-trip'
          break;
        case '舊的已加入':
          target = 'my-plan-joined'
          break;
        default:
          console.log('Nav error!');
      }
      return target;
    },
    currentComponentData: function() {
      let data
      switch (this.navInfo[this.currentNav].name) {
        case '登山計畫':
          data = this.groupList;
          break;
        case '收藏的路線':
          data = this.favorites;
          break;
        case '參加的活動':
          data = this.joinedGroupList;
          break;
        case '舊的行程頁':
          data = this.journeys;
          break;
        case '舊的已加入':
          data = this.joinedList;
          break;
        default:
          console.log('Component data error!');
      }
      return data;
    },
	},
  watch: {
    
  },
  mounted: async function(){
    await this.refresh();
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),

    refresh: async function() {
      await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
      try {
        // 登山計畫資料
        this.groupList.splice(0, this.groupList.length);
        this.groupList = await this.$store.dispatch('api/getGroupListPromise');
        this.navInfo[0].length = this.groupList.length;

        // 收藏的路線資料
        this.favorites.splice(0, this.favorites.length);
        this.favorites = await this.$store.dispatch('api/getPathFavoritePromise');
        this.navInfo[1].length = this.favorites.length;

        // 參加的活動資料
        this.joinedGroupList.splice(0, this.joinedGroupList.length);
        const res = await this.$store.dispatch('api/getGroupOwnPromise');
        this.joinedGroupList = res.group;
        this.navInfo[2].length = this.joinedGroupList.length;

        // // 舊的行程頁資料
        // this.journeys.splice(0, this.journeys.length);
        // this.journeys = await this.$store.dispatch('api/getJourneyListPromise');
        // console.log(this.journeys)
        // this.navInfo[3].length = this.journeys.length;
        //
        // // 舊的已加入資料
        // this.joinedList.splice(0, this.joinedList.length);
        // const dataJoinedList = await this.$store.dispatch('api/getMyJoinedListPromise');
        // dataJoinedList.forEach(item => {
        //   this.joinedList.push(item.journey[0]);
        // });
        // this.navInfo[4].length = this.joinedList.length;
      } catch (error) {
        await this.$store.dispatch('appendErrorMsg', error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
      }
    },
    toggleNav: async function(index, item) {
      await this.refresh();
      this.currentNav = index;

      const label = `MyPlanTab${item.name}`;
      this.$gtag.event('CustomClick', {
        event_label: label,
      });
      console.log(label);
      // window.trackCustomEvent('Clicked', 'change-tab-' + index);
    },
    deleteBtnClicked: async function(e) {
      let target = e[0]
      let r = confirm('確定刪除【' + target.name + '】?');
      if (r) {
        try {
          // window.trackCustomEvent('Clicked', 'delete-card');
          await this.$store.dispatch('api/postJourneyDeletePromise', target.serial);
          this.journeys.splice(target.index, 1);
          this.navInfo[0].length = this.journeys.length;
          alert('成功刪除【' + target.name + '】');
        } catch (error) {
          await this.$store.dispatch('appendErrorMsg', error.toString());
        }
      }
    },
    parseDateStr: function(dStr) {
      let t = moment(dStr);
      return t.format('YYYY/MM/DD(dd)');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.my-plan {
  background: $color-white;
  min-height: calc(var(--unit-100vh) - 70px);
  @include smaller-than-small {
    background: $color-black-200;
    min-height: calc(var(--unit-100vh) - 56px);
  }
  .container {
    padding: 0 0 2rem;
  }
  .my-plan-nav {
    position: sticky;
    top: 70px;
    gap: .5rem;
    background: $color-white;
    padding: 1rem .25rem .5rem;
    overflow-x: auto;
    @include smaller-than-small {
      top: 56px;
      padding: .5rem .5rem 0;
      margin-bottom: 1px;
    }
  }
  .nav-item {
    cursor: pointer;
    flex: 0 0 auto;
    color: $color-bk-tp-25;
    padding: .5rem;
    &.active {
      color: $color-bk-tp-85;
      font-weight: 700;
      border-bottom: 4px solid $color-black-900;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.my-plan {
}
</style>