<template>
  <div class="layout-main">
    <section class="main search">
      <div class="container">
        <div class="searchbar-group">
          <div class="d-flex align-items-center flex-column">
            <p class="searchbar-title text-center">
              <span class="underline cursor-pointer" @click="scrollToSection('plan')">路程規劃</span>
              <span>、</span>
              <span class="underline cursor-pointer" @click="scrollToSection('manager')">行程管理</span><br>
              <span class="underline cursor-pointer" @click="scrollToSection('mountain')">生成登山計畫書</span>
            </p>
            <p class="searchbar-desc fs-h3 d-flex align-items-center">
              揪山讓登山行程隨時規劃
              <img :src="require('@/assets/images/svgs/Logo.svg')" alt="icon">
            </p>
          </div>
          <div class="input-group input-group-lg mb-3">
            <input type="text" class="form-control" id="basic-search" placeholder="搜尋路線或山岳名稱" v-model="searchText" @keydown.enter="searchClicked" @click="inputClicked">
            <div class="input-group-append" id="button-search">
              <button class="btn btn-outline-secondary rounded" type="button" @click="searchClicked">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 4C6.91015 4 4 6.91015 4 10.5C4 14.0899 6.91015 17 10.5 17C14.0899 17 17 14.0899 17 10.5C17 6.91015 14.0899 4 10.5 4ZM2 10.5C2 5.80558 5.80558 2 10.5 2C15.1944 2 19 5.80558 19 10.5C19 12.4869 18.3183 14.3145 17.176 15.7618L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L15.7618 17.176C14.3145 18.3183 12.4869 19 10.5 19C5.80558 19 2 15.1944 2 10.5Z"/>
                </svg>
              </button>
              <div class="search-suggestion">
                <div class="title fs-body-14 mb-2">熱門搜尋</div>
                <div class="btn-group mb-4">按鈕</div>
                <div class="title fs-body-14 mb-2">搜尋紀錄</div>
                <div class="desc">尚無搜尋紀錄</div>
              </div>
            </div>
          </div>
        </div>
        <div class="guide-card row no-gutters">
          <div class="col-12">
            <div class="desc-block text-lg-center"> 
              <div class="brief fs-body-16" ref="mountain">自動生成</div>
              <div class="title fs-h1">登山計畫書</div>
              <ul class="feature d-flex justify-content-center flex-column flex-lg-row">
                <li class="desc">
                  <Icon name="Check" size="36" />
                  行程列表清楚明瞭
                </li>
                <li class="desc">
                  <Icon name="Check" size="36" />
                  路線軌跡對照
                </li>
                <li class="desc">
                  <Icon name="Check" size="36" />
                  裝備清單不遺忘
                </li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-lg-6 guide-image with-three-body">
            <img :src="require('@/assets/images/index/new-guide-00-1.png')" alt="guide image">
          </div>
          <div class="col-12 col-lg-6 three-body-group d-flex">
            <img class="three-body-item" :src="require('@/assets/images/index/new-guide-00-2.png')" alt="guide image">
            <img class="three-body-item" :src="require('@/assets/images/index/new-guide-00-3.png')" alt="guide image">
            <img class="three-body-item" :src="require('@/assets/images/index/new-guide-00-4.png')" alt="guide image">
          </div>
        </div>
        <div class="guide-card row no-gutters">
          <div class="col-12 col-lg-5">
            <div class="desc-block">
              <div class="brief fs-body-16" ref="plan">製作計畫</div>
              <div class="title fs-h1">用更簡易的方式，製作更貼近的計畫</div>
              <ul class="feature">
                <li class="desc">
                  <Icon name="Check" size="36" />
                  設定個人行走時間
                </li>
                <li class="desc">
                  <Icon name="Check" size="36" />
                  調整 0.5 ~ 2 的行走速率
                </li>
                <li class="desc">
                  <Icon name="Check" size="36" />
                  自訂休息時間＆留宿點
                </li>
              </ul>
              <hr>
              <div class="fs-body-20">
                “安排路線時，只要選擇出發時間，系統會將會以上河 1 的時間自動排序，節省了我處理路程規劃的大量時間。”
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-7">
            <div class="two-body-group">
              <img class="two-body-item" :src="require('@/assets/images/index/new-guide-01-1.png')" alt="guide image">
              <img class="two-body-item" :src="require('@/assets/images/index/new-guide-01-2.png')" alt="guide image">
            </div>
          </div>
        </div>
        <div class="guide-card row no-gutters">
          <div class="col-12 col-lg-4 order-lg-1 d-flex align-items-center">
            <div class="desc-block">
              <div class="brief fs-body-16">我的行程</div>
              <div class="title fs-h1">一頁即全部</div>
              <ul class="feature">
                <li class="desc">
                  <Icon name="Check" size="36" />
                  下載登山計畫書
                </li>
                <li class="desc">
                  <Icon name="Check" size="36" />
                  隨時編輯行程內容
                </li>
                <li class="desc">
                  <Icon name="Check" size="36" />
                  加入團員輕鬆成團
                </li>
                <li class="desc">
                  <Icon name="Check" size="36" />
                  下載離線地圖 GPX
                </li>
              </ul>
            </div>
          </div>
            <div class="col-12 col-lg-8 guide-image d-flex flex-lg-row-reverse align-items-center align-items-lg-stretch schedule-body-group">
              <img class="schedule-body-item" :src="require('@/assets/images/index/new-guide-02-1.png')" alt="guide image">
              <img class="schedule-body-item" :src="require('@/assets/images/index/new-guide-02-3.png')" alt="guide image">
              <img class="schedule-body-item" :src="require('@/assets/images/index/new-guide-02-2.png')" alt="guide image">
            </div>
        </div>
        <div class="guide-card row no-gutters">
          <div class="col-12 col-lg-5">
            <div class="desc-block">
              <div class="brief fs-body-16" ref="manager">參團管理</div>
              <div class="title fs-h1">輕鬆參團快速申請</div>
              <ul class="feature">
                <li class="desc">
                  <Icon name="Check" size="36" />
                  只有主揪可以瀏覽全部資料
                </li>
                <li class="desc">
                  <Icon name="Check" size="36" />
                  行程結束後資料由系統自動刪除
                </li>
                <li class="desc">
                  <Icon name="Check" size="36" />
                  個人資料隱私保障提升
                </li>
                <li class="desc">
                  <Icon name="Check" size="36" />
                  搭配入園證小外掛一起使用
                </li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-lg-7 d-flex justify-content-center justify-content-lg-stretch">
            <b-carousel
                class="d-none d-sm-block"
                :interval="2000"
            >
              <b-carousel-slide
                  :img-src="require('@/assets/images/index/new-guide-03-1.png')"
              ></b-carousel-slide>
              <b-carousel-slide
                  :img-src="require('@/assets/images/index/new-guide-03-2.png')"
              ></b-carousel-slide>
              <b-carousel-slide
                  :img-src="require('@/assets/images/index/new-guide-03-3.png')"
              ></b-carousel-slide>
              <b-carousel-slide
                  :img-src="require('@/assets/images/index/new-guide-03-4.png')"
              ></b-carousel-slide>

            </b-carousel>
            <div class="carousel-mobile d-sm-none">
              <div class="carousel-mobile-group">
                <img class="carousel-mobile-item" :src="require('@/assets/images/index/new-guide-03-1.png')" alt="guide image">
                <img class="carousel-mobile-item" :src="require('@/assets/images/index/new-guide-03-2.png')" alt="guide image">
                <img class="carousel-mobile-item" :src="require('@/assets/images/index/new-guide-03-3.png')" alt="guide image">
                <img class="carousel-mobile-item" :src="require('@/assets/images/index/new-guide-03-4.png')" alt="guide image">
              </div>
            </div>
          </div>
        </div>
        <div class="guide-card row no-gutters">
          <div class="col-12 mb-lg-5">
            <div class="desc-block text-lg-center">
              <div class="brief fs-body-16">實用工具</div>
              <div class="title fs-h1 mb-3">路線軌跡圖</div>
              <div class="desc">下載軌跡圖後，可以使用於任何一張照片</div>
            </div>
          </div>
          <div class="col-12 guide-image-group justify-content-lg-center">
            <img class="tool-image" :src="require('@/assets/images/index/new-guide-04-1.png')" alt="guide image">
            <img class="tool-image" :src="require('@/assets/images/index/new-guide-04-2.png')" alt="guide image">
          </div>
        </div>
        <div class="guide-card row no-gutters">
          <div class="col-12 mb-lg-5">
            <div class="desc-block text-lg-center">
              <div class="brief fs-body-16">小幫手們</div>
              <div class="title fs-h1 mb-3">工具整合</div>
              <div class="desc">我們還整合了以下第三方服務，讓您規劃時事半功倍</div>
            </div>
          </div>
          <div class="col-12 d-flex flex-column flex-lg-row">
            <div class="tool-block d-flex">
              <img :src="require('@/assets/images/icon/line-icon.svg')" alt="icon">
              <div>
                <div class="fs-h4 mb-2">Line 路線查詢小幫手</div>
                <div class="desc fs-body-16 mb-3">可在 Line 直接查詢路線資料，Line ID: @joyhike。</div>
                <a class="fs-body-16" href="https://lin.ee/WEgkz1v" target="_blank">
                  加小幫手朋友
                  <Icon name="link_arrow" size="24" />
                </a>
              </div>
            </div>
            <div class="tool-block d-flex">
              <img :src="require('@/assets/images/icon/tool-icon.svg')" alt="icon">
              <div>
                <div class="fs-h4 mb-2">申請入園證小幫手</div>
                <div class="desc fs-body-16 mb-3">附屬於 Chrome 的小外掛，可以快速的將申請資料帶入網頁，不用再一個一個輸入。 </div>
                <a class="fs-body-16" href="https://chrome.google.com/webstore/detail/joyhike-%E6%8F%AA%E5%B1%B1%E7%94%B3%E8%AB%8B%E5%85%A5%E5%9C%92%E8%AD%89%E5%B0%8F%E5%B9%AB%E6%89%8B/cdhnohnokbmmmeclnaploegjlggjachb" target="_blank">
                  下載小外掛
                  <Icon name="link_arrow" size="24" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="guide-card with-hr row advantage-row">
          <div class="advantage-block col-12 col-lg-4">
            <div class="fs-title-14">Time</div>
            <div class="fs-h3">花更少時間</div>
            <div class="desc fs-body-18">
              省下每次製作計畫時的重複事項，由系統直接帶入路程資料。
            </div>
          </div>
          <div class="advantage-block col-12 col-lg-4">
            <div class="fs-title-14">Precise</div>
            <div class="fs-h3">做更完善的計畫</div>
            <div class="desc fs-body-18">
              使用約上河 1 的行走時間，調配自己的行走速率以及設定每日留宿點，並讓參加者都可一目瞭然。
            </div>
          </div>
          <div class="advantage-block col-12 col-lg-4">
            <div class="fs-title-14">Goal</div>
            <div class="fs-h3">達更安全的登山行程</div>
            <div class="desc fs-body-18">
              讓規劃內容更符合真實上山狀況，更能掌握上山後所面臨的風險。
            </div>
          </div>
        </div>
        <div class="guide-card with-hr">
          <div class="data-block w-100 d-flex justify-content-center">
            <div class="data-card text-center">
              <div class="num mb-2">5.1 萬+</div>
              <div class="fs-body-14">人次使用</div>
            </div>
            <div class="data-card text-center">
              <div class="num mb-2">5300+</div>
              <div class="fs-body-14">製作計畫</div>
            </div>
          </div>
          <div class="slogan fs-h1 text-center">
            “我們不再資訊分散地完成登山計畫，因為揪山幫我們做到了。”
          </div>
        </div>
        <div class="guide-card with-hr d-flex justify-content-center d-lg-none">
          <div class="btn common-square-btn fs-title-16 justify-content-center cursor-pointer" @click="clickSearchBtn">
            製作我的登山計劃
            <img :src="require('@/assets/images/icon/arrow_left.svg')" alt="icon">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'Index',
  data() {
    return {
      searchText: '',
      isScrolling: false,
    };
  },
  props: {
  },
  components: {
  },
  computed: {
    user() {
      return this.$store.getters.userData;
    },
    carouselImgWidth() {
      return '390';
    },
    carouselImgHeight() {
      return '768';
    },
	},
  watch: {
    
  },
  mounted() {
    
	},
  methods: {
    searchClicked: function(event) {
      if(event.isComposing) {
        return
      }
      if (this.searchText.length === 0) {
        alert('請至少輸入一個字');
      } else {
        // window.trackCustomEvent('Search', this.searchText);
        this.$router.push({
          name: 'SearchResult',
          query: {
            query: this.searchText,
          }
        })
        // window.location.href = '/search_result?query=' + this.searchText;
      }
    },
    inputClicked: function() {
      // window.trackCustomEvent('Clicked', 'search-input');
    },
    scrollToSection(sectionRef) {
      // this.$gtag.event("CustomEvent", {
      //   event_category: "Click",
      //   event_label: "Test",
      //   value: ""
      // });
      // this.$gtag.event('scrollTo', { target: sectionRef });
      const element = this.$refs[sectionRef];
      const offset = document.querySelector('.navbar').offsetHeight;
      window.scrollTo({
        behavior: 'smooth',
        top: element.getBoundingClientRect().top - offset - 16,
      })
    },
    clickSearchBtn: function() {
      // document.querySelector('.search-tool-btn').click();
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })

      function handleScrollEnd() {
        document.querySelector('#basic-search').focus();
        document.removeEventListener('scrollend', handleScrollEnd);
      }
      document.addEventListener('scrollend', handleScrollEnd);
    },
  }
}
</script>


<style lang="scss">
  @import "src/assets/scss/basic";
  @import "src/assets/scss/index";
  .main.search {
    overflow: hidden;
    .carousel-mobile {
      position: relative;
      width: 100%;
      padding-bottom: 180%;
      .carousel-mobile-group {
        position: absolute;
        top: 0;
        left: -1rem;
        display: flex;
        align-items: center;
        width: calc(100% + 2rem);
        height: 100%;
        overflow-x: scroll;
        padding: 0 1rem;
      }
      .carousel-mobile-item {
        height: 100%;
        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
    }
  }
</style>
