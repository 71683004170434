<template>
  <div class="layout-main bg-white">
    <section class="journey edit-mode custom-z-index" v-if="initReady">
      <div class="header journey-block">
        <b-button class="back-btn d-md-none" variant="link" @click="backToDetail">
          <Icon name="arrow_back_ios" size="24"></Icon>
        </b-button>
        <div class="fs-h2 text-center">編輯登山計畫</div>
        <button class="finish-btn btn common-oval-btn dark fs-body-18" @click="updateSettings">完成</button>
      </div>
      <div class="journey-name journey-block">
        <div class="fs-body-16">路線名稱</div>
        <div class="fs-body-18">{{ journeyData.name }}</div>
      </div>
      <div class="journey-content journey-block">
        <div class="block-title fs-h4">計畫內容</div>
        <div class="operating-list">
          <path-operating-Item class="cursor-pointer" :icon="operatingList.time.srcName" :name="operatingList.time.name" @itemClick="openDatePicker">
            <common-date-picker ref="commonDatePicker" minDate="today" v-model="startDate"></common-date-picker>
          </path-operating-Item>
          <div @click="setJourneyTemplateClicked">
            <journey-day-select-item v-model="selectTemplate" :options="templateOptions" :name="operatingList.days.name" :icon="operatingList.days.src"></journey-day-select-item>
          </div>
        </div>
      </div>
      <div class="journey-plan journey-block" v-if="journeyData">
        <div class="block-title fs-h4">路線規劃</div>
        <div class="content-list">
          <div class="content-item d-flex justify-content-between">
            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="directions_walk" size="20" />
                <div class="info-text fs-body-16">行走</div>
              </div>
              <div class="info-value fs-h2">
                <span v-text="directionWalkHr"></span>
                <span>:</span>
                <span v-text="directionWalkMin"></span>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="distance" size="20" />
                <div class="info-text fs-body-16">距離</div>
              </div>
              <div class="info-value fs-h2">
                <span v-text="distance"></span>
                <span>km</span>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="climb-up" size="20" />
                <div class="trail-info-text fs-body-16">總升</div>
              </div>
              <div class="info-value fs-h2">
                <span v-text="climbUp"></span>
                <span>m</span>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="climb-down" size="20" />
                <div class="info-text fs-body-16">總降</div>
              </div>
              <div class="info-value fs-h2">
                <span v-text="climbDown"></span>
                <span>m</span>
              </div>
            </div>
          </div>
          <div class="content-item d-flex justify-content-center" @click="climbRateClicked">
            <speed-controller v-model="climbRate"></speed-controller>
          </div>
          <div class="content-item">
            <div class="journey-info row">
              <div class="col-12 col-md-6">
                <div class="box trail-map" :class="{ 'scale-active': mapScale }">
                  <img class="thumbnail d-md-none" :src="[journeyData.picUrl]" alt="路線縮圖">
                  <button class="btn scale d-md-none" @click="mapScaleToggle">
                    <Icon :name="mapScale ? 'map-reduce' : 'map-enlarge'" size="24" alt="地圖縮放鍵" class="map-scale-btn" />
                  </button>
                  <div class="path-map-group custom-z-index common-transition">
                    <path-map ref="map" v-if="shownPathFullPosition.length > 0" :path="shownPathFullPosition" :milestones="shownPathMilestones"></path-map>
                    <button class="btn scale d-md-none" @click="mapScaleToggle">
                      <Icon :name="mapScale ? 'map-reduce' : 'map-enlarge'" size="24" alt="地圖縮放鍵" class="map-scale-btn" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="box overflow-auto" v-if="generatedJourney">
                  <journey-viewer :journey="generatedJourney" :show-opt-btn="true" :show-weather="false" :show-start-time-select="true" @optclick="milestoneOptClicked" @start-time-changed="dayStartTimeChanged"></journey-viewer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="journey-order journey-block">-->
<!--        <div class="block-title fs-h4">服務預訂</div>-->
<!--      </div>-->
    </section>
    <b-modal ref="milestone-opt-modal" size="sm" centered v-model="showOptModal" hide-header hide-footer>
      <div class="p-2">
        <h5 class="text-center mb-0">{{milestoneOptName}}</h5>
      </div>
      <div class="list-group">
        <a href="#" v-if="!clickedMilestoneIsSplitPoint" class="list-group-item list-group-item-action" @click.prevent="showRestTimeOptModal">設定休息時間</a>
        <a href="#" v-if="!clickedMilestoneIsSplitPoint" class="list-group-item list-group-item-action" @click.prevent="setJourneySplit">設定為留宿點</a>
        <a href="#" v-if="clickedMilestoneIsSplitPoint" class="list-group-item list-group-item-action" @click.prevent="removeJourneySplit">移除留宿點</a>
      </div>
    </b-modal>
    <b-modal modal-class="rest-time" ref="diagram-modal" title="設定休息時間" size="sm" centered v-model="showRestModal" @ok="milestoneRestTimeModalSaveClicked">
      <select v-model="milestoneRestTime">
        <option v-for="(item, index) in milestoneRestTimeOpts" :key="index" :value="item" v-text="item + '分鐘'"></option>
      </select>
    </b-modal>
  </div>
</template>

<script>
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import setHideNav from "@/assets/js/setHideNav";
import PathOperatingItem from "@/views/path/PathOperatingItem.vue";
import JourneyDaySelectItem from "@/views/journey/JourneyDaySelectItem.vue";
import PathMap from "@/components/common/PathMap.vue";
import CommonDatePicker from "@/components/common/CommonDatePicker.vue";
import JourneyViewer from "@/components/common/JourneyViewer.vue";
import SpeedController from "@/components/common/SpeedController.vue";
import svgEdit from "@/assets/images/icon/edit-gray.svg";
import moment from "moment/moment";
import {mapState} from "vuex";

export default {
  mixins: [setHideNav, commonBusyAndErrorHandling],
  name: 'GroupDetailEdit',
  data() {
    return {
      initReady: false,
      readInfoBusyName: 'GroupDetailEdit',

      operatingList: {
        time: { name:'出發日期', srcName: 'Date' },
        days: { name:'套用範本', srcName: 'Date' },
      },

      groupData: null,
      journeyData: null,

      selectTemplate: 0,

      // 顯示在各區域的計算後資訊
      shownPathMilestones: [],
      shownPathFullPosition: [],
      shownPathFullHeights: [],

      pathTemplates: [],

      // 在這個頁面中可以被編輯的內容
      startDate: '',
      durationDays: 1,
      climbRate: 1,
      journeySplitIndexs: [],
      restAry: [],
      startTimes: [],
      journeyPath: [],

      loadedMilestones: [],
      loadedRoutes: [],

      showOptModal: false,
      milestoneOptIndex: -1,
      milestoneOptName: '',

      showRestModal: false,
      milestoneRestTimeSettingIndex: -1,
      milestoneRestTime: 15,
      milestoneRestTimeOpts: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 60, 75, 90],

      mapScale: false,

      imageSrc: {
        svgEdit: svgEdit,
      },

    };
  },
  props: {
  },
  components: {
    SpeedController,
    CommonDatePicker,
    PathOperatingItem,
    JourneyDaySelectItem,
    PathMap,
    JourneyViewer,
  },
  computed: {
    ...mapState(['user']),

    directionWalkHr() {
      if(this.journeyData && this.journeyData.pathInfo) {
        return parseInt(this.journeyData.pathInfo.totalWalkTime/60);
      } else {
        return '';
      }
    },
    directionWalkMin() {
      if(this.journeyData && this.journeyData.pathInfo) {
        return String(parseInt(this.journeyData.pathInfo.totalWalkTime%60)).padStart(2, '0');
      } else {
        return '';
      }
    },
    distance() {
      if(this.journeyData && this.journeyData.pathInfo) {
        return (this.journeyData.pathInfo.totalDistance/1000).toFixed(1);
      } else {
        return '';
      }
    },
    climbUp() {
      if(this.journeyData && this.journeyData.pathInfo) {
        return (this.journeyData.pathInfo.totalUp).toFixed(0);
      } else {
        return '';
      }
    },
    climbDown() {
      if(this.journeyData && this.journeyData.pathInfo) {
        return (this.journeyData.pathInfo.totalDown).toFixed(0);
      } else {
        return '';
      }
    },
    templateOptions: function() {
      const opts = [{
        index: -1,
        name: '選擇範本',
        val: 0,
      }];
      const templateNames = ['神速', '單攻', '兩天', '三天', '四天', '五天', '六天', '七天', '八天', '九天', '十天'];
      this.pathTemplates.map((template, index) => {
        opts.push({
          index: index,
          name: template.durationDays > 10 ? '長期': templateNames[template.durationDays],
          val: template.durationDays,
        });
      });
      return opts;
    },
    selectedTemplateIndex() {
      if (this.journeyData) {
        return this.pathTemplates.findIndex(template => template.durationDays === this.journeyData.durationDays);
      } else {
        return -1;
      }
    },
    selectedTemp: function() {
      if (this.selectedTemplateIndex === -1) {
        return null;
      }
      return this.pathTemplates[this.selectedTemplateIndex];
    },
    clickedMilestoneIsSplitPoint() {
      const i = this.journeySplitIndexs.indexOf(this.milestoneOptIndex);
      return i !== -1
    },
    generatedJourney() {
      if(this.journeyData) {
        const genJ = {... this.journeyData};

        genJ.durationDays = this.journeySplitIndexs.length + 1;
        let cDate = moment(this.startDate);
        genJ.startDate = cDate.format('YYYY-MM-DD');
        cDate.add(genJ.durationDays - 1, 'days');
        genJ.endDate = cDate.format('YYYY-MM-DD');

        genJ.journeySplitIndexs.splice(0, genJ.journeySplitIndexs.length);
        for (let i=0;i<this.journeySplitIndexs.length;i++) {
          genJ.journeySplitIndexs.push(this.journeySplitIndexs[i]);
        }
        genJ.climbRate = this.climbRate;

        const newJourney = [];

        let stepTime = moment(`${genJ.startDate} ${this.startTimes[0]}`);
        let dayCount = 0;
        for (let i=0;i<this.journeyPath.length;i++) {
          const newJ = {... this.journeyPath[i]};
          newJ.startTimestamp = stepTime.valueOf();
          newJ.startTime = stepTime.format('YYYY-MM-DD HH:mm');

          if (this.journeySplitIndexs.indexOf(i) !== -1) {
            dayCount = dayCount + 1;
            stepTime = moment(`${genJ.startDate} ${this.startTimes[dayCount]}`).add(dayCount, 'days');
          } else {
            if (newJ.type === 'route') {
              const detail = this.loadedRoutes.find((item) => {return item.serial === newJ.serial});
              newJ.detail = detail;
              const costMinutes = (newJ.direction === 0)?detail.consuming:detail.returnConsuming;
              const lastCostMinutes = costMinutes * this.climbRate;
              stepTime.add(lastCostMinutes, 'minutes');
            } else if (newJ.type === 'milestone') {
              const detail = this.loadedMilestones.find((item) => {return item.serial === newJ.serial});
              newJ.detail = detail;
              const costMinutes = this.restAry[i/2];
              stepTime.add(costMinutes, 'minutes');
            }
          }
          newJ.endTimestamp = stepTime.valueOf();
          newJ.endTime = stepTime.format('YYYY-MM-DD HH:mm');

          newJ.duration = (newJ.endTimestamp - newJ.startTimestamp) * this.journeyData.climbRate / (60 * 1000);

          newJourney.push(newJ);
        }
        genJ.journey = newJourney;

        return genJ;
      } else {
        return null
      }
    },
	},
  watch: {
    selectTemplate(val) {
      if (val === 0) {
        return;
      }
      const r = window.confirm('確定套用此範本嗎?');
      if (r) {
        const temp = this.pathTemplates.find((item) => {
          return item.durationDays === val;
        });
        if (temp) {
          this.journeyPath.splice(0, this.journeyPath.length);
          for (let i=0;i<temp.journey.length;i++){
            this.journeyPath.push(temp.journey[i]);
          }

          for (let i=0;i<this.restAry.length;i++) {
            this.restAry[i] = 0;
          }

          this.journeySplitIndexs.splice(0, this.journeySplitIndexs.length);
          this.startTimes.splice(0, this.startTimes.length);

          this.startTimes.push(moment(this.journeyPath[0].endTimestamp).format('HH:mm'));
          for (let i=0;i<temp.journeySplitIndexs.length;i++) {
            this.journeySplitIndexs.push(temp.journeySplitIndexs[i]);
            this.startTimes.push(moment(this.journeyPath[this.journeySplitIndexs[i]].endTimestamp).format('HH:mm'));
          }
        } else {
          alert('找不到範本');
        }
      } else {
        this.selectTemplate = 0;
      }
    },
  },
  async mounted() {
    const journeyId = this.$route.params.id;
    await this.readInfo(journeyId);
    this.initReady = true;
	},
  methods: {
    async readPathTemplates(pathSerial) {
      const dayTemplatesRequest = await this.$store.dispatch('api/getPathDayTemplatePromise', pathSerial);
      let templates = [];
      for (const dt of dayTemplatesRequest) {
        const templateDetail = await this.$store.dispatch('api/readJourneyDetailByTemplatePromise', dt.serial);
        templateDetail.tempId = dt.serial;
        templates.push(templateDetail);
      }
      templates = templates.sort(function(a, b) {
        return a.durationDays - b.durationDays;
      });
      this.pathTemplates.push(...templates);
    },
    saveLoadedMilestone(m) {
      const existM = this.loadedMilestones.find((item) => {
        return item.serial === m.serial;
      });
      if (!existM) {
        this.loadedMilestones.push(m);
      }
    },
    saveLoadedRoute(r) {
      const existR = this.loadedRoutes.find((item) => {
        return item.serial === r.serial;
      });
      if (!existR) {
        this.loadedRoutes.push(r);
      }
    },
    async readInfo(journeyId) {
      await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
      try {
        this.groupData = await this.$store.dispatch('api/getGroupDetailPromise', journeyId);
        this.journeyData = await this.$store.dispatch('api/getJourneyDetailPromise', this.groupData.journeys[0]);
        this.buildFullPathAndHeights(this.journeyData.journey);
        this.buildPathMilestones(this.journeyData.journey);

        await this.readPathTemplates(this.journeyData.baseOnPathSerial);

        this.startDate = this.journeyData.startDate;
        this.durationDays = this.journeyData.durationDays;
        this.climbRate = this.journeyData.climbRate;

        this.journeySplitIndexs.splice(0, this.journeySplitIndexs.length);
        this.startTimes.push(moment(this.journeyData.journey[0].endTimestamp).format('HH:mm'));
        for (let i=0;i<this.journeyData.journeySplitIndexs.length;i++) {
          this.journeySplitIndexs.push(this.journeyData.journeySplitIndexs[i]);
          this.startTimes.push(moment(this.journeyData.journey[this.journeySplitIndexs[i]].endTimestamp).format('HH:mm'));
        }

        this.restAry.splice(0, this.restAry.length);
        for (let i=0;i<this.journeyData.journey.length;i++) {
          const j = this.journeyData.journey[i];
          if (j.type === 'milestone') {
            this.saveLoadedMilestone(j.detail);
            if (this.journeySplitIndexs.indexOf(i) === -1) {
              // 計算休息時間
              const restMinutes = (j.endTimestamp - j.startTimestamp) / (60 * 1000);
              this.restAry.push(restMinutes);
            } else {
              this.restAry.push(0);
            }
          } else if (j.type === 'route') {
            this.saveLoadedRoute(j.detail);
          }

          this.journeyPath.push({... j});
        }

      } catch (error) {
        await this.$store.dispatch('appendErrorMsg', error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
      }
    },
    buildFullPathAndHeights(journeyItems) {
      this.shownPathFullPosition.splice(0, this.shownPathFullPosition.length);
      this.shownPathFullHeights.splice(0, this.shownPathFullHeights.length);

      journeyItems.forEach((item) => {
        if (item.type === 'milestone') {
          this.shownPathFullPosition.push({
            lat: item.detail.lat,
            lng: item.detail.lng,
          });
        } else if (item.type === 'route') {
          const detail = item.detail;
          const routepts = detail.routepts;
          const heights = detail.heights;

          if (item.direction === 0) {
            this.shownPathFullPosition = this.shownPathFullPosition.concat(
                routepts.map((point) => ({ lat: point.lat, lng: point.lng }))
            );
            this.shownPathFullHeights = this.shownPathFullHeights.concat(heights);
          } else {
            this.shownPathFullPosition = this.shownPathFullPosition.concat(
                routepts.reverse().map((point) => ({ lat: point.lat, lng: point.lng }))
            );
            this.shownPathFullHeights = this.shownPathFullHeights.concat(heights.reverse());
          }
        }
      });
    },
    buildPathMilestones(journeyItems) {
      this.shownPathMilestones.splice(0, this.shownPathMilestones.length);

      for (const item of journeyItems) {
        if (item.type === 'milestone') {
          const found = this.shownPathMilestones.filter((m) => {
            return m.serial === item.detail.serial;
          });
          if (found.length === 0) {
            this.shownPathMilestones.push(item.detail);
          }
        }
      }
    },
    openDatePicker() {
      this.$gtag.event('CustomClick', {
        event_label: "GroupEditDate",
      });
      console.log('open flatpickr');
      this.$refs.commonDatePicker.openFlatpickr();
    },
    milestoneOptClicked(index) {
      this.showOptModal = true;
      this.milestoneOptIndex = index;
      this.milestoneOptName = this.journeyData.journey[this.milestoneOptIndex].detail.name;
    },
    setJourneySplit() {
      this.$gtag.event('CustomClick', {
        event_label: "GroupEditSetCamp",
      });
      let inserted = false;
      for (let i=0;i<this.journeySplitIndexs.length;i++) {
        if (this.journeySplitIndexs[i] > this.milestoneOptIndex) {
          this.journeySplitIndexs.splice(i, 0, this.milestoneOptIndex);
          this.startTimes.splice(i+1, 0, "03:00");
          inserted = true;
          break;
        }
      }
      if (!inserted) {
        this.journeySplitIndexs.push(this.milestoneOptIndex);
        this.startTimes.push("03:00");
      }
      this.showOptModal = false;
    },
    removeJourneySplit() {
      this.$gtag.event('CustomClick', {
        event_label: "GroupEditRemoveCamp",
      });
      const index = this.journeySplitIndexs.indexOf(this.milestoneOptIndex);
      if (index !== -1) {
        this.journeySplitIndexs.splice(index, 1);
        this.startTimes.splice(index + 1, 1);
        const j = this.journeyPath[this.milestoneOptIndex];
        const stepTime = moment(j.startTimestamp);
        const costMinutes = this.restAry[this.milestoneOptIndex/2];
        stepTime.add(costMinutes, 'minutes');
        this.journeyPath[this.milestoneOptIndex].endTimestamp = stepTime.valueOf();

        this.showOptModal = false;
      }
    },
    showRestTimeOptModal() {
      this.$gtag.event('CustomClick', {
        event_label: "GroupEditSetRestTime",
      });
      this.showOptModal = false;
      this.milestoneRestTimeSettingIndex = this.milestoneOptIndex/2;
      this.milestoneRestTime = this.restAry[this.milestoneRestTimeSettingIndex];
      this.showRestModal = true;
    },
    milestoneRestTimeModalSaveClicked() {
      const index = this.milestoneRestTimeSettingIndex;
      this.$set(this.restAry, index, this.milestoneRestTime);
    },
    dayStartTimeChanged(data) {
      this.$set(this.startTimes, data.index, data.startTime);
    },
    mapScaleToggle() {
      this.mapScale = !this.mapScale;
    },
    backToDetail() {
      this.$router.push({ name: 'GroupDetail' });
    },
    async updateSettings() {
      this.$gtag.event('CustomClick', {
        event_label: "GroupEditSave",
      });
      await this.handleAsyncTask(async () => {
        const filteredJourney = this.generatedJourney.journey.map(item => (
          item.type === 'milestone'?
          {
            type: item.type,
            serial: item.serial,
            startTimestamp: item.startTimestamp,
            duration: item.duration,
            endTimestamp: item.endTimestamp
          }:
          {
            type: item.type,
            serial: item.serial,
            startTimestamp: item.startTimestamp,
            duration: item.duration,
            endTimestamp: item.endTimestamp,
            direction: item.direction
          }
        ));
        const settingsData = {
          serial: this.journeyData.serial,
          startDate: this.generatedJourney.startDate,
          endDate: this.generatedJourney.endDate,
          durationDays: this.generatedJourney.durationDays,
          climbRate: this.generatedJourney.climbRate,
          journeySplitIndexs: this.generatedJourney.journeySplitIndexs,
          journey: filteredJourney,
        }
        console.log(settingsData);
        const data = await this.$store.dispatch('api/postJourneyUpdatePromise', settingsData);
        console.log(data);
        let r = confirm('儲存編輯成功！');
        if(r) {
          await this.$router.push({ name: 'GroupDetail' });
        }
      });
    },
    setJourneyTemplateClicked() {
      this.$gtag.event('CustomClick', {
        event_label: "GroupEditJourneyTemplate",
      });
    },
    climbRateClicked() {
      this.$gtag.event('CustomClick', {
        event_label: "GroupEditClimbRate",
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.journey.edit-mode {
  .header.journey-block {
    .btn.back-btn {
      position: absolute;
      top: 28px;
      left: 0;
      width: fit-content;
      padding: .5rem;
    }
    .btn-link {
      color: $color-dark;
    }
  }
  @include smaller-than-medium {
    .header.journey-block {
      padding: 12px;
      .btn.back-btn {
        top: 3px;
        left: .5rem;
      }
      .fs-h2 {
        color: $color-bk-tp-85;
        font-size: 18px;
        line-height: 1.5;
      }
      .btn.finish-btn {
        top: 3px;
        right: 0.5rem;
        color: $color-bk-tp-85;
        background: $color-white;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.5;
        border: none;
        padding: .5rem 1rem;
      }
    }
    .journey-block.journey-name {
      .fs-body-16 {
        margin-bottom: 12px;
      }
    }
    .block-title {
      margin-bottom: 12px;
    }
    .operating-item:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/journey";
</style>
