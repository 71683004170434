<template>
  <footer class="layout-footer">
    <div class="container d-flex">
      <div class="guideline-group">
        <div class="title fs-small-11">關於</div>
        <a class="link fs-body-14" href="#" id="footer-blogger" @click.prevent="blogBtnClicked">部落格</a>
        <router-link class="link fs-body-14" :to="{name: 'DatabaseRange'}" id="footer-database" @click.native="dbBtnclicked">資料庫範圍</router-link>
        <!-- <a class="link fs-body-14" href="#">揪山的使用方式</a> -->
        <router-link class="link fs-body-14" :to="{name: 'Term'}" id="footer-term" @click.native="termBtnclicked">使用條款 / 隱私權聲明</router-link>
        <div class="fs-body-14">揪山股份有限公司  90839274</div>
      </div>
      <div class="guideline-group">
        <div class="title fs-small-11">聯繫我們</div>
        <span class="link fs-body-14">hello@joyhike.com</span>
        <div class="d-flex">
          <a class="link fs-body-14 mr-2" href="#" id="footer-official" @click.prevent="lineLinkBtnClicked">
            <Icon class="contact-icon" name="line_green" size="24" />
          </a>
          <a class="link fs-body-14 mr-2" href="#" @click.prevent="fbLinkBtnClicked" id="footer-official">
            <Icon class="contact-icon" name="Facebook" size="24" />
          </a>
          <a class="link fs-body-14" href="#" @click.prevent="igLinkBtnClicked" id="footer-official">
            <Icon class="contact-icon" name="Instagram" size="24" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>


<script>

export default {
  name: 'Index',
  data() {
    return {
    };
  },
  props: {
  },
  components: {
  },
  computed: {
	},
  watch: {
    
  },
  mounted() {
    
	},
  methods: {
    blogBtnClicked() {
      this.$gtag.event('CustomClick', {
        event_label: "FooterBlog",
      });
      window.open('https://blog.joyhike.com/', '_blank');
    },
    dbBtnclicked() {
      this.$gtag.event('CustomClick', {
        event_label: "FooterDB",
      });
    },
    termBtnclicked() {
      this.$gtag.event('CustomClick', {
        event_label: "FooterTerm",
      });
    },
    lineLinkBtnClicked() {
      this.$gtag.event('CustomClick', {
        event_label: "FooterSNSLine",
      });
      window.open('https://lin.ee/0kSLswL', '_blank');
    },
    fbLinkBtnClicked() {
      this.$gtag.event('CustomClick', {
        event_label: "FooterSNSFb",
      });
      window.open('https://www.facebook.com/Joyhike-105485528667840/', '_blank');
    },
    igLinkBtnClicked() {
      this.$gtag.event('CustomClick', {
        event_label: "FooterSNSIg",
      });
      window.open('https://instagram.com/joyhike?utm_medium=copy_link', '_blank');
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/basic";
  .guideline-group .title.fs-small-11{
    transform-origin: bottom left;
  }
</style>
