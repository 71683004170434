<template>
  <div class="journey-day">
    <div class="journey-head" v-if="showHeaderInfo">
      <div class="day-features">
        <div class="day-feature">
          <div class="day-feature-img">
            <img :src="imageSrc.svgDirectionsWalk" alt="icon"/>
            <span>行走</span>
          </div>
          <p><span v-if="totalCostMinutes >= 60"><span class="big-text">{{parseInt(totalCostMinutes / 60)}}</span> h </span><span class="big-text">{{parseInt(totalCostMinutes % 60)}}</span> mins </p>
        </div>

        <div class="day-feature">
          <div class="day-feature-img">
            <img :src="imageSrc.svgRest" alt="icon"/>
            <span>休息</span>
          </div>
          <p><span v-if="totalRestMinutes >= 60"><span class="big-text">{{parseInt(totalRestMinutes / 60)}}</span> h </span><span class="big-text">{{parseInt(totalRestMinutes % 60)}}</span> mins </p>
        </div>

        <div class="day-feature">
          <div class="day-feature-img">
            <img :src="imageSrc.svgDistance" alt="icon"/>
            <span>距離</span>
          </div>
          <p><span class="big-text">{{totalDistance.toFixed(1)}}</span>km</p>
        </div>

        <div class="day-feature">
          <div class="day-feature-img">
            <img :src="imageSrc.svgClimbUp" alt="icon"/>
            <span>爬升</span>
          </div>
          <p><span class="big-text">{{totalClimbUp.toFixed(0)}}</span>m</p>
        </div>

        <div class="day-feature">
          <div class="day-feature-img">
            <img :src="imageSrc.svgClimbDown" alt="icon" />
            <span>下降</span>
          </div>
          <p><span class="big-text">{{totalClimbDown.toFixed(0)}}</span>m</p>
        </div>
      </div>

      <div class="day-start-time-select" v-if="showStartTimeSelect">
        <span>出發時間</span>
        <select :value="startTime" @input="startTimeChanged">
          <option v-for="(item, index) in startTimeOptValues" v-text="item" :value="item" :key="index"></option>
        </select>
      </div>
    </div>
    <div class="journey-flex">
      <div class="journey-row" v-for="(item, index) in itemList" :key="index">
        <div class="journey-time">
          <div class="milestone-time" v-if="item.type === 'milestone' && showTimestamp">
            {{__timeStampToTime(item.startTimestamp)}}
          </div>

          <div class="milestone-time" v-if="item.type === 'break' && showTimestamp">
            {{__timeStampToTime(item.endTimestamp)}}
          </div>

          <div class="route-time" v-if="item.type === 'route'">
            {{__durationFromTimeStamps(item.endTimestamp - item.startTimestamp)}} 分鐘
          </div>
        </div>

        <div class="journey-icon">
          <div class="icon-line up">
          </div>
          <div class="icon-line down">
          </div>

          <div class="icon-milestone" v-if="item.type === 'milestone'">
            <div :class="__parseIconClass(item.detail) + ' icon-milestone-content'">
              <img class="mountain" title="三角點" :src="imageSrc.svgMountains" alt="icon"/>
              <img class="parking" title="停車場" :src="imageSrc.svgParking" alt="icon"/>
              <img class="house" title="山屋" :src="imageSrc.svgHouse" alt="icon"/>
              <img class="toilet" title="生態廁所" :src="imageSrc.svgToilet" alt="icon"/>
              <img class="camp" title="可紮營" :src="imageSrc.svgCamp" alt="icon" />
              <img class="entrance" title="入山口" :src="imageSrc.svgWalk" alt="icon"/>
              <img class="water" title="取水點" :src="imageSrc.svgWater" alt="icon"/>
              <img class="wifi" title="訊號點" :src="imageSrc.svgWifi" alt="icon"/>
            </div>
          </div>
        </div>

        <div class="journey-detail">

          <div class="milestone-detail" v-if="item.type === 'milestone'">
            <p class="milestone-name">{{item.detail.name}}</p>

            <a class="milestone-opt" v-if="showOptBtn" href="#" @click.prevent="optClicked(item)">...</a>

<!--            <div v-if="showWeather" class="milestone-weather">-->
<!--              <milestone-weather :apiurl="apiurl" :milestone-serial="item.detail.serial" :timestamp="item.startTimestamp"></milestone-weather>-->
<!--            </div>-->
          </div>

          <div class="route-detail" v-if="item.type === 'route'">
            <p class="route-name">
              <span class="route-distance" :title="item.detail.distance + '公尺'"><span class="t-bold">{{(item.detail.distance / 1000.0).toFixed(1)}}</span>km</span>

              <span>
                <span class="t-bold" v-show="heightArray[index] >= 0"><img style="height: 1em;" :src="imageSrc.svgClimbUp" alt="icon"/></span>
                <span class="t-bold" v-show="heightArray[index] < 0"><img style="height: 1em;" :src="imageSrc.svgClimbDown" alt="icon"/></span>
                <span class="t-bold">{{Math.abs(heightArray[index])}}</span>m
              </span>
            </p>
          </div>

          <div class="milestone-detail" v-if="item.type === 'break'">
            <p class="milestone-name">休息 {{__durationFromTimeStamps(item.duration)}} 分鐘</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import svgDirectionsWalk from "@/assets/images/milestone-icon/directions_walk.svg"
import svgRest from "@/assets/images/milestone-icon/new-rest.svg"
import svgDistance from "@/assets/images/milestone-icon/distance.svg"
import svgClimbUp from "@/assets/images/milestone-icon/climb-up.svg"
import svgClimbDown from "@/assets/images/milestone-icon/climb-down.svg"
import svgMountains from "@/assets/images/milestone-icon/mountains.svg"
import svgParking from "@/assets/images/milestone-icon/parking.svg"
import svgHouse from "@/assets/images/milestone-icon/house.svg"
import svgToilet from "@/assets/images/milestone-icon/toilet.svg"
import svgCamp from "@/assets/images/milestone-icon/camp.svg"
import svgWalk from "@/assets/images/milestone-icon/walk.svg"
import svgWater from "@/assets/images/milestone-icon/water.svg"
import svgWifi from "@/assets/images/milestone-icon/wifi.svg"

export default {
  name: 'JourneyDay',
  data() {
    return {
      itemList: [],
      heightArray: [],
      totalDistance: 0,
      totalCostMinutes: 0,
      totalRestMinutes: 0,
      startTime: '07:00',
      startTimeOptValues: [],
      totalClimbUp: 0,
      totalClimbDown: 0,

      imageSrc: {
        svgDirectionsWalk: svgDirectionsWalk,
        svgRest: svgRest,
        svgDistance: svgDistance,
        svgClimbUp: svgClimbUp,
        svgClimbDown: svgClimbDown,

        svgMountains: svgMountains,
        svgParking: svgParking,
        svgHouse: svgHouse,
        svgToilet: svgToilet,
        svgCamp: svgCamp,
        svgWalk: svgWalk,
        svgWater: svgWater,
        svgWifi: svgWifi,
      },
    }
  },
  props: {
    climbUnit: {
      type: Number,
      required: false,
      default: 1,
    },
    journeyItem: {
      required: true,
    },
    showHeaderInfo: {
      type: Boolean,
      required: false,
      default: false,
    },
    showStartTimeSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTimestamp: {
      type: Boolean,
      required: false,
      default: false,
    },
    showOptBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    showWeather: {
      type: Boolean,
      required: false,
      default: false,
    },
    // apiurl: {
    //   type: Object,
    //   required: true,
    // }
  },
  async created() {
    let tempT = moment().second(0).minute(0).hour(0);
    for (let i=0;i<(24 * 2);i++){
      this.startTimeOptValues.push(tempT.format('HH:mm'));
      tempT.add(30, 'minutes');
    }

    this.__setup();
  },
  async mounted() {
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    journeyItem: {
      handler() {
        this.__setup();
      },
      deep: true,
    }
  },
  methods: {
    __setup() {
      this.itemList.splice(0, this.itemList.length);
      this.heightArray.splice(0, this.heightArray.length);
      for (let i=0;i<this.journeyItem.length;i++) {
        const item = {
          ... this.journeyItem[i],
          oriIndex: i,
        };
        if (item.type === 'milestone') {
          this.heightArray.push(0);
          if (i === 0) {
            this.startTime = this.__timeStampToTime(item.startTimestamp);
          }
        } else if (item.type === 'route') {
          this.heightArray.push(this.journeyItem[i+1].detail.height - this.journeyItem[i-1].detail.height);
        }
        this.itemList.push(item);

        if (item.type === 'milestone' && item.endTimestamp > item.startTimestamp) {
          this.itemList.push({
            type: 'break',
            duration: item.endTimestamp - item.startTimestamp,
            endTimestamp: item.endTimestamp,
          });
          this.heightArray.push(0);
        }
      }
      this.__calcTotalDistance();
      this.__calcTotalCostMinutes();
      this.__calcTotalRestMinutes();
      this.__calcClimb();
    },
    __calcClimb() {
      this.totalClimbUp = 0;
      this.totalClimbDown = 0;
      let lastHeight = 0;
      let inited = false;
      for (const j of this.journeyItem) {
        if (j.type === 'route' && this.climbUnit > 0) {
          let i = 0;
          let step = this.climbUnit;
          if (j.direction === 1) {
            //反向
            i = j.detail.heights.length - 1;
            step = -1 * this.climbUnit;
          }
          for (;i<j.detail.heights.length && i>=0; i += step) {
            const h = j.detail.heights[i];
            if (!inited) {
              inited = true;
            } else {
              const dis = h - lastHeight;
              if (dis > 0) {
                this.totalClimbUp += dis;
              } else {
                this.totalClimbDown -= dis;
              }
            }
            lastHeight = h;
          }
        } else if (j.type === 'milestone') {
          const h = j.detail.height;
          if (!inited) {
            inited = true;
          } else {
            const dis = h - lastHeight;
            if (dis > 0) {
              this.totalClimbUp += dis;
            } else {
              this.totalClimbDown -= dis;
            }
          }
          lastHeight = h;
        }
      }
    },
    __calcTotalDistance() {
      this.totalDistance = this.journeyItem.reduce((sum, item) => {
        if (item.type === 'route') {
          return sum += (item.detail.distance / 1000.0);
        }
        return sum;
      }, 0);
    },
    __calcTotalCostMinutes() {
      this.totalCostMinutes = this.journeyItem.reduce((sum, item) => {
        if (item.type === 'route') {
          return sum += ((item.endTimestamp - item.startTimestamp) / (1000 * 60) );
        }
        return sum;
      }, 0);
    },
    __calcTotalRestMinutes() {
      this.totalRestMinutes = this.journeyItem.reduce((sum, item) => {
        if (item.type === 'milestone') {
          return sum += ((item.endTimestamp - item.startTimestamp) / (1000 * 60) );
        }
        return sum;
      }, 0);
    },
    __timeStampToTime(t) {
      return moment(t).format("HH:mm");
    },
    __durationFromTimeStamps(t) {
      return parseInt(moment.duration(t).asMinutes());
    },
    __parseIconClass(m) {
      if (m.triangle) {
        return 'icon-mountain';
      } else if (m.parking) {
        return 'icon-parking';
      } else if (m.entrance) {
        return 'icon-entrance';
      } else if (m.house) {
        return 'icon-house';
      } else if (m.waterspot === 1) {
        return 'icon-water';
      } else if (m.campable) {
        return 'icon-camp';
        // } else if (m.signal) {
        //   return 'icon-wifi';
      } else if (m.toilet) {
        return 'icon-toilet';
      }
      return 'icon-none';
    },
    optClicked(item) {
      this.$emit('optclick', item.oriIndex);
    },
    startTimeChanged(evt) {
      this.$gtag.event('CustomClick', {
        event_label: "GroupEditDepartTime",
      });
      this.$emit('start-time-changed', evt.target.value);
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/basic";
  .journey-day {
    --icon-line-color: #DBDBDB;
    padding: 0.5rem 0rem;
    background-color: #fff;
  }

  .t-bold {
    font-weight: 1000;
  }

  .journey-head {

  }

  .day-features {
    display: flex;
    justify-content: flex-start;
    /* padding: 0 20px; */
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: .5em;
    overflow: hidden;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .day-feature {
    flex: 0 0 auto;
    text-align: center;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .day-feature-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .day-feature-img>img {
    /* height: 28px; */
    display: block;
    transform: scale(.8);
    margin-left: auto;
    margin-right: auto;
    /* margin: 0 auto 4px; */
  }

  .day-feature-img>span {
    font-size: .6rem;
  }

  .day-feature>p {
    font-size: .7rem;
    margin-bottom: 0;
  }

  .day-feature .big-text {
    font-size: 1.1rem;
    font-weight: 600;
  }

  .day-start-time-select {
    margin-bottom: .5em;
    font-size: .8rem;
    color: #ccc;
  }

  .day-start-time-select>select {
    margin-left: 1rem;
    border: none;
  }

  .journey-flex {
    display: flex;
    flex-wrap: wrap;
    padding-top: 14px;
    /* flex-direction: column; */
  }

  .journey-head+.journey-flex {
    padding-top: 0px;
  }

  .journey-row {
    flex: 0 0 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  /*
  .journey-row:first-child a.milestone-opt, .journey-row:last-child a.milestone-opt {
    display: none;
  }
  */

  .journey-time {
    flex: 0 0 60px;
    text-align: right;
  }

  .milestone-time {
    font-size: .9em;
  }

  .route-time {
    font-size: .9em;
    opacity: .25;
  }

  .journey-icon {
    flex: 0 0 50px;
    width: 50px;
    min-height: 36px;
    position: relative;
    place-self: stretch;
  }

  .icon-line {
    position: absolute;
    width: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: var(--icon-line-color);
  }

  .icon-line.up {
    top: 0;
    height: 12px;
  }

  .icon-line.down {
    bottom: 0;
    height: calc(100% - 12px);
  }

  .journey-row:first-child .icon-line.up {
    display: none;
  }

  .journey-row:last-child .icon-line.down {
    display: none;
  }

  .icon-milestone {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border: solid 4px var(--icon-line-color);
    /* border: none; */
    top: 12px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  .icon-milestone-content {
    position: absolute;
    width: 24px;
    height: 24px;
    /* background-color: #E5DC81; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-milestone-content.icon-none {
    display: none;
  }

  .icon-milestone-content>img {
    display: none;
    width: 100%;

  }

  .icon-milestone-content.icon-mountain>img.mountain {
    display: block;
  }

  .icon-milestone-content.icon-parking>img.parking {
    display: block;
  }

  .icon-milestone-content.icon-entrance>img.entrance {
    display: block;
  }

  .icon-milestone-content.icon-water>img.water {
    display: block;
  }

  .icon-milestone-content.icon-camp>img.camp {
    display: block;
  }

  .icon-milestone-content.icon-wifi>img.wifi {
    display: block;
  }

  .icon-milestone-content.icon-toilet>img.toilet {
    display: block;
  }

  .icon-milestone-content.icon-house>img.house {
    display: block;
  }

  .icon-milestone-content.icon-house>img.house {
    display: block;
  }



  .journey-detail {
    flex: 10 1;
  }

  .milestone-detail {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .milestone-name {
    margin: 0;
    font-size: .9em;
    flex: 1 1;
    padding-right: .5em;
  }

  .milestone-opt {
    font-size: .9em;
    letter-spacing: 1px;
    display: block;
    padding: 0px 10px 10px;
    font-weight: 1000;
    color: #000;
    flex: 0 0 auto;
  }

  .milestone-opt:hover {
    color: #000;
    text-decoration: none;
  }

  .milestone-weather {
    flex: 0 0 110px;
  }

  .route-detail {

  }

  .route-name {
    margin: 0;
    font-size: .9em;
    opacity: .25;
  }

  .route-distance {
    padding-right: 1em;
  }

  @media (min-width: 992px) {
    .journey-day {
      padding: 0.5rem 2rem;
    }

    .day-features::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    .day-features::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    .day-features::-webkit-scrollbar-thumb {
      background: #ddd;
    }

    .day-features::-webkit-scrollbar-thumb:hover {
      background: #888;
    }
  }
</style>
