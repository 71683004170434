<template>
  <div class="layout-main">
    <section class="main search">
      <div class="container" v-if="inited">
        <div class="" v-show="searchResultList.length === 0">
          <p class="text-center mt-5 font-weight-bold">沒有找到符合的結果。</p>
        </div>
        <div class="row">
          <div class="search-result-list col-9">
            <SearchResultItem
                v-for="(item) in searchResultList"
                :key="item.serial"
                :data="item"
            ></SearchResultItem>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SearchResultItem from "@/views/search/SearchResultItem";

export default {
  name: 'SearchResult',
  data() {
    return {
      readInfoBusyName: 'READINFOBUSY',

      inited: false,
      searchResultList: [],
    };
  },
  props: {
  },
  components: {
    SearchResultItem,
  },
  computed: {
    user() {
      return this.$store.getters.userData;
    },
    queryTxt() {
      return this.$route.query.query ? this.$route.query.query : '';
    }
	},
  watch: {
    async queryTxt() {
      await this.refresh();
    },
  },
  async mounted() {
    await this.refresh();
    if(this.searchResultList.length === 0) {
      this.$gtag.event('CustomSearchNoResult', {
        search_text: this.queryTxt,
      });
    } else {
      this.$gtag.event('CustomSearchHasResult', {
        search_text: this.queryTxt,
      });
    }
	},
  methods: {
    getSearchPath: async function(query_txt) {
      await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
      try {
        const res = await this.$store.dispatch('api/getPathSearchPromise', query_txt);
        return res;
      } catch (error) {
        await this.$store.dispatch('appendErrorMsg', error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
      }
    },
    async refresh() {
      this.searchResultList.splice(0, this.searchResultList.length);
      if (this.queryTxt.trim().length > 0) {
        const searchText = decodeURIComponent(this.queryTxt.trim());
        const searchResult = await this.getSearchPath(searchText);
        for (const c of searchResult) {
          this.searchResultList.push(c);
        }
      }
      this.inited = true;
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/basic";
</style>
